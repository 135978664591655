/* 计数器表单项组件 */
<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <el-input-number v-model="itemVal" v-bind="[itemData]" @input="input" @change="change"></el-input-number>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: Number
    }
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  methods: {
    // input方法触发父组件同步更新数据
    input(e) {
      this.$emit('input', this.itemVal)
    },
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData)
      }
    }
  }
}

</script>
